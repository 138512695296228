import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Push_Service/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import PushTable from 'components/Web_User_Interface/1440p_Series/Alarm/Push_Service/pushTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Push Service",
  "path": "/Web_User_Interface/1440p_Series/Alarm/Push_Service/",
  "dateChanged": "2020-03-12",
  "author": "Mike Polinowski",
  "excerpt": "Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for IOS or ANDROID devices.",
  "image": "./WebUI_1440p_SearchThumb_Alarm_Push_Service.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Alarm_Push_Service.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Push Service' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for IOS or ANDROID devices.' image='/images/Search/WebUI_1440p_SearchThumb_Alarm_Push_Service.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Alarm_Push_Service.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Alarm/Push_Dienst/' locationFR='/fr/Web_User_Interface/1440p_Series/Alarm/Push_Service/' crumbLabel="Push Service" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ab5dc578ff1997583e2f6737c7272e5/47aef/1440p_Settings_Alarm_Push.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACTklEQVQoz2WQS08TYRhG+Re6JAot7RQN9EKw7XQ6vU07lHa+memUGiJiJKkWQkQIlwrRhABRIGhMUEOsQkATNl4IG10YVi7c6A86Skkl0cWTk3dz8jxvmyRJSD4fHtdlQrkRtKm3JGvbJGoviFW3/4tc3SY98RL93g5X+uJkMyna29u5ePFCk21erxdJ8uHuuIRcGKWyeIAz9xpnvoE928Ce+4ezDYYW3jC8tEckWUSOynT7riJ5u/F4pJZQwuVyEekPUTbzlAwdRwzgGDp2IYtVyP6lNahh5jOIfIYBzUCRE8SVJGo8TTDQR1tzcksYiTJUuY5THqLklBGmhSGsJoVpI4TAGl+jNL+LNVanMijIaadCFTmawN8bOmt4mjNhBKdcxrZLmKaJYRhN2rZ9FsvCroxg35rELFWo3tRxbAU5ohCTE+cNW8JwOIxlmQijiDCMprAVIcxmU0sUsYqD2KZg4rZGUY+iyAopVSUYDLUme+nodJFUIryrFzh+mGV6tEDesLBFAXO4inH/Ffrdx9QTOs+VFJOpLFszd3AGVOIxmXRSJRAInjd0u9309vSQjkcx9AwDWQ1Ny5FIZtD0IhPjNRaXV9jaWGdjYYbVep3Pm+vUhEZUllGVBH5/8PyHHo+nObuj001/f4xwOE5CURk2cjTWZvh+tMfh/j6/fnzj5MMBP7984njnKUs3NNLJODFZJeAPnTfs6upq5vQOX4uhqSqjZoalMcHByhQnH3f5enTIs+VFNpce8H5zlf0nj5guJSkV00Qjcfx/hL8B/geH5TsdYcEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ab5dc578ff1997583e2f6737c7272e5/e4706/1440p_Settings_Alarm_Push.avif 230w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/d1af7/1440p_Settings_Alarm_Push.avif 460w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/7f308/1440p_Settings_Alarm_Push.avif 920w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/29832/1440p_Settings_Alarm_Push.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ab5dc578ff1997583e2f6737c7272e5/a0b58/1440p_Settings_Alarm_Push.webp 230w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/bc10c/1440p_Settings_Alarm_Push.webp 460w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/966d8/1440p_Settings_Alarm_Push.webp 920w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/0ca5b/1440p_Settings_Alarm_Push.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ab5dc578ff1997583e2f6737c7272e5/81c8e/1440p_Settings_Alarm_Push.png 230w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/08a84/1440p_Settings_Alarm_Push.png 460w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/c0255/1440p_Settings_Alarm_Push.png 920w", "/en/static/3ab5dc578ff1997583e2f6737c7272e5/47aef/1440p_Settings_Alarm_Push.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ab5dc578ff1997583e2f6737c7272e5/c0255/1440p_Settings_Alarm_Push.png",
              "alt": "Web User Interface - 1440p Series - Alarm Push Service",
              "title": "Web User Interface - 1440p Series - Alarm Push Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PushTable mdxType="PushTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      